
















































































import { Component, Vue, Watch } from "vue-property-decorator";
import { IAppearanceTreeNodeGet, IUserProfileCreate } from "@/interfaces";
import {
  dispatchGetUsers,
  dispatchCreateUser,
  dispatchGetTreeNodes,
} from "@/store/admin/actions";
import { readCityId } from "@/store/main/getters";

@Component
export default class CreateUser extends Vue {
  public valid = false;
  public fullName: string = "";
  public email: string = "";
  public isActive: boolean = true;
  public isCityAdmin: boolean = false;
  public appearancesWriteAccess: number[] = [];

  private treeNodes: IAppearanceTreeNodeGet[] = [];

  @Watch("isCityAdmin")
  public isCityAdminChanged() {
    if (this.isCityAdmin) {
      this.appearancesWriteAccess = [];
    }
  }

  public changeAppearanceWriteAccess(value: boolean, id: number) {
    if (value) {
      this.appearancesWriteAccess.push(id);
    } else {
      this.$delete(
        this.appearancesWriteAccess,
        this.appearancesWriteAccess.indexOf(id)
      );
    }
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
    this.treeNodes = await dispatchGetTreeNodes(
      this.$store,
      readCityId(this.$store)
    );
    this.reset();
  }

  public reset() {
    this.fullName = "";
    this.email = "";
    this.isActive = true;
    this.isCityAdmin = false;
    this.appearancesWriteAccess = [];
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUserProfileCreate = {
        email: this.email,
      };
      if (this.fullName) {
        updatedProfile.full_name = this.fullName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      updatedProfile.is_active = this.isActive;
      updatedProfile.is_city_admin = this.isCityAdmin;
      updatedProfile.city_id = readCityId(this.$store);
      await dispatchCreateUser(this.$store, updatedProfile);
      this.$router.push("/main/city/users");
    }
  }
}
