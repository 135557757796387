
































































import { Component, Vue, Watch } from "vue-property-decorator";
import { IUserProfileCreate } from "@/interfaces";
import {
  dispatchGetUsers,
  dispatchCreateUser,
  dispatchGetCities,
} from "@/store/admin/actions";
import { readAdminCities } from "@/store/admin/getters";
import { readHasCityAccess } from "@/store/main/getters";

@Component
export default class CreateUser extends Vue {
  public valid = false;
  public fullName: string = "";
  public email: string = "";
  public cityId: number = 0;
  public isActive: boolean = true;
  public isSuperuser: boolean = false;
  public isCityAdmin: boolean = false;

  get cities() {
    return readAdminCities(this.$store);
  }

  @Watch("isCityAdmin")
  private updateCityAdmin(value: boolean) {
    if (value) {
      this.isSuperuser = false;
    }
  }

  @Watch("isSuperuser")
  private updateSuperuser(value: boolean) {
    if (value) {
      this.isCityAdmin = false;
    }
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
    await dispatchGetCities(
      this.$store,
      readHasCityAccess(this.$store) ?? false
    );
    this.reset();
  }

  public reset() {
    this.fullName = "";
    this.email = "";
    this.isActive = true;
    this.isSuperuser = false;
    this.isCityAdmin = false;
    this.cityId = this.cities[0].id;
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUserProfileCreate = {
        email: this.email,
      };
      if (this.fullName) {
        updatedProfile.full_name = this.fullName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      updatedProfile.is_active = this.isActive;
      updatedProfile.is_superuser = this.isSuperuser;
      if (!this.isSuperuser) {
        updatedProfile.is_city_admin = this.isCityAdmin;
        updatedProfile.city_id = this.cityId;
      }
      await dispatchCreateUser(this.$store, updatedProfile);
      this.$router.push("/main/admin/users");
    }
  }
}
